<template>
  <div class="dashboard-view-page-new">
    <van-nav-bar
      :title="dashboardData['title']"
      left-arrow
      safe-area-inset-top
      placeholder
      @click-left="onClickLeft"
      :style="{'padding-top': statusBarHeight + 'px'}"
    />
    <div class="dashboard-view-div" :style="{height: `calc(100vh - 90px - ${moreHeight}px)`}">
      <DashboardViewNew :dashboardModel="dashboardModel"/>
    </div>
  </div>
</template>
<script>
// import './style/dashboard-view-new.less'
// import './style/dashboard-child.less'
// import './style/dashboard.less'
// import PropertyHelp from './data/PropertyHelp'
import DashboardViewNew from './DashboardViewNew'

import { mapState } from 'vuex'
/**
 *
 */
export default {
  name: 'DashboardPage',
  components: {
    DashboardViewNew
  },
  props: {},
  data() {
    return {
      id: null,
      dashboardData: {
        title: '仪表盘'
      },
      dashboardModel: {
        children: []
      }
      // dataLoading: false,
      // showOverlyChart: false,
      // gridLayoutColNum: 100,
      // viewWidth: 1024,
      // viewHeight: 720,
      // xRatio: 1,
      // yRatio: 1,
      // colWidth: 1,
      // rowHeight: 1,
      // showNewQuery: false,
      // interactInputVisible: false,
      // keyword: '',
      // newQueryModalVisible: false,
      // contextTarget: null
    }
  },
  computed: {
    ...mapState({
      statusBarHeight: state => state.global.statusBarHeight,
      moreHeight: state => state.global.moreHeight
    })
    // bgStyle() {
    //   if (this.dashboardModel) {
    //     const backgroundStyle = PropertyHelp.getBackgroundStyle(this.dashboardModel)
    //     const borderStyle = PropertyHelp.getBorderStyle(this.dashboardModel)
    //     return Object.assign({}, backgroundStyle, borderStyle)
    //   } else {
    //     return {}
    //   }
    //   // return {}
    // },
    // viewStyle() {
    //   return {
    //     width: '100%',
    //     height: this.dashboardModel.stageHeight * this.yRatio + 'px'
    //   }
    // }
  },
  methods: {
    // childStyle(child) {
    //   const { x, y, w, h, z } = child
    //   const oneColWidth = this.dashboardModel.stageWidth / 20
    //   const width = w * this.xRatio * oneColWidth
    //   const height = h * this.yRatio
    //   const left = x * this.xRatio * oneColWidth
    //   const top = y * this.yRatio
    //   const paddingObj = this.getChildPaddingStyle(child)
    //   return {
    //     width: `${width}px`,
    //     height: `${height}px`,
    //     left: `${left}px`,
    //     top: `${top}px`,
    //     zIndex: z,
    //     ...paddingObj
    //   }
    // },
    // getChildPaddingStyle (child) {
    //   const styleObj = {}
    //   // 垂直部分
    //   const maxY = Math.max(...this.dashboardModel.children.map(item => { return item['y'] }))
    //   if (child.y === 0 && child.y === maxY) { // 第一行且最后一行
    //     styleObj.paddingTop = this.dashboardModel.space + 'px'
    //     styleObj.paddingBottom = this.dashboardModel.space + 'px'
    //   } else if (child.y === 0) { // 第一行
    //     styleObj.paddingTop = this.dashboardModel.space + 'px'
    //     styleObj.paddingBottom = this.dashboardModel.space / 2 + 'px'
    //   } else if (child.y === maxY) { // 最后一行
    //     styleObj.paddingTop = this.dashboardModel.space / 2 + 'px'
    //     styleObj.paddingBottom = this.dashboardModel.space + 'px'
    //   } else { // 中间行
    //     styleObj.paddingTop = this.dashboardModel.space / 2 + 'px'
    //     styleObj.paddingBottom = this.dashboardModel.space / 2 + 'px'
    //   }
    //   // 水平部分
    //   if (child.x === 0 && child.x + child.w === 20) { // 一列只有一个
    //     styleObj.paddingLeft = this.dashboardModel.space + 'px'
    //     styleObj.paddingRight = this.dashboardModel.space + 'px'
    //   } else if (child.x === 0) { // 第一列
    //     styleObj.paddingLeft = this.dashboardModel.space + 'px'
    //     styleObj.paddingRight = 0
    //   } else if (child.x + child.w === 20) { // 最后一列
    //     styleObj.paddingLeft = this.dashboardModel.space / 2 + 'px'
    //     styleObj.paddingRight = this.dashboardModel.space + 'px'
    //   } else { // 中间列
    //     styleObj.paddingLeft = this.dashboardModel.space / 2 + 'px'
    //     styleObj.paddingRight = 0
    //   }
    //   return styleObj
    // },
    // // childStyle(child) {
    // //   const { x, y, w, h, z } = child
    // //   const margin = this.dashboardModel.space / 2
    // //   const oneColWidth = this.dashboardModel.stageWidth / 20
    // //   const width = w * this.xRatio * oneColWidth - margin * 2
    // //   const height = h * this.yRatio - margin * 2
    // //   const left = x * this.xRatio * oneColWidth
    // //   const top = y * this.yRatio
    // //   return {
    // //     width: `${width}px`,
    // //     height: `${height}px`,
    // //     left: `${left}px`,
    // //     top: `${top}px`,
    // //     margin: `${margin}px`,
    // //     zIndex: z
    // //   }
    // // },
    // containerResize() {
    //   const { clientWidth, clientHeight } = this.$el
    //   const { stageWidth } = this.dashboardModel
    //   this.viewWidth = clientWidth
    //   this.viewHeight = clientHeight
    //   this.xRatio = this.viewWidth / stageWidth
    //   this.yRatio = this.xRatio
    // },
    getDashboardById() {
      this.$api.dashboard.getDashboardById(this.id).then(res => {
        this.dashboardData = res
        this.dashboardModel = JSON.parse(res['data'])
        // this.containerResize()
      })
    },
    onClickLeft () {
      window.history.go(-1)
    }
  },
  mounted() {
    this.id = this.$route.params['id']
    if (this.id) {
      this.getDashboardById()
    } else {
      this.$router.replace({ name: 'DashboardList' })
    }
  }
}
</script>
<style lang="less">
.dashboard-view-page-new {
  .van-nav-bar {
    background-image: url("~@/assets/background.png");
    background-size: 100%;
    background-repeat: no-repeat;
    // &::after {
      // border-bottom-width: 0
    // }
    .van-nav-bar__content {
      height: 40px;
      .van-nav-bar__title {
        font-size: 19px;
        font-weight: bold;
        color: #ffffff;
        font-family: PingFang SC;
      }
      .van-nav-bar__left {
        .van-icon-arrow-left {
          color: #000000;
          font-weight: bold;
          color: #ffffff;
          &::before {
            vertical-align: middle;
          }
        }
      }
    }
  }
  .dashboard-view-div {
    overflow: auto;
  }
}
</style>
